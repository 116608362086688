import { useEffect, useMemo, useState } from "react";
import { APIResponse } from "@/lib/apiFetch";
import { DonationsResponse } from "@/services/donations.service";
import { routes } from "@/services/donations.service";
import { fetcher } from "@/lib/apiFetch";
import useSWR, { preload } from "swr";
import { fetcherV2 } from "@/services/api";

interface Props {
  initialPage: number;
  limit: number;
  sortBy: string;
  sortDirection: "ASC" | "DESC";
  select?: string[];
  campaignId?: number;
  donorId?: number;
  type?: string;
  search?: string;
}

export function useDonations({
  initialPage,
  limit,
  sortBy,
  sortDirection,
  select,
  campaignId,
  donorId,
  type,
  search,
}: Props) {
  const [page, setPage] = useState<number>(initialPage);
  const [pages, setPages] = useState<number>(0);

  const { data, error, mutate, isLoading } = useSWR(
    () => [
      routes.donations,
      {
        page,
        limit,
        sortBy,
        sortDirection,
        select,
        campaignId,
        donorId,
        type,
        search,
      },
    ],
    fetcherV2<DonationsResponse>,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      suspense: false,
      shouldRetryOnError: false,
    }
  );

  const preloadData = (increment: -1 | 1) => {
    preload(
      [
        routes.donations,
        {
          page: page + increment,
          limit,
          sortBy,
          sortDirection,
          select,
          campaignId,
        },
      ],
      fetcherV2
    );
  };

  useEffect(() => {
    if (data && !isLoading) {
      setPages(data.pages);
    }
  }, [data, isLoading]);

  return {
    donations: data?.result ?? [],
    total: data?.total ?? 0,
    page: page + 1,
    pages,
    next: () => setPage((p) => p + 1),
    previous: () => setPage((p) => p - 1),
    canPrevious: page > 0,
    canNext: page + 1 < pages,
    isLoading: !error && !data,
    isError: error,
    pageSize: limit,
    refresh: mutate,
    preloadData,
  };
}
