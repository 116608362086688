import Page from "@/containers/Page";
import { useAccountStats } from "@/hooks/useAccountStats";
import { currency } from "@/lib/format";
import { IconType } from "react-icons";
import { FaHandHoldingHeart } from "react-icons/fa";
import { RiUserHeartFill } from "react-icons/ri";
import { usePageInfo } from "@/hooks/usePageInfo";
import { useAccount } from "@/hooks/useAccount";
import { useEffect, useMemo } from "react";
import Loader from "@/components/Loader";
import { useNotifications } from "@/hooks/NotificationsProvider";
import { useStripeConnectLink } from "@/hooks/useStripeConnectLink";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { useDonations } from "@/hooks/useDonations";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import { Chart } from "@/containers/Chart";
import { endOfMonth, formatDistanceToNow, startOfMonth } from "date-fns";

interface CardProps {
  title: string;
  description?: string;
  value?: string | number;
  secondaryValue?: string | number;
  Icon: IconType;
  loading: boolean;
  iconColor?: string;
  highlight?: boolean;
}

const MyCard = ({
  title,
  description,
  value,
  secondaryValue,
  loading,
  highlight,
}: CardProps) => (
  <Card className={cn({ "bg-card text-primary": highlight })}>
    <CardHeader className="pb-2">
      <CardTitle className="text-sm font-normal uppercase">{title}</CardTitle>
      {/* <CardDescription className="text-xs text-muted-foreground">
        {description}
      </CardDescription> */}
    </CardHeader>
    <CardContent>
      {loading ? (
        <Loader size={24} />
      ) : (
        <div>
          <div className="font-semibold text-2xl">{value}</div>
          <div className={cn("font-light text-xs text-green-500", {})}>
            {secondaryValue}
          </div>
        </div>
      )}
    </CardContent>
  </Card>
);

export default function Home() {
  const { addNotification } = useNotifications();
  const { accountStats, isLoading } = useAccountStats({});
  const range = useMemo(() => {
    return {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
    };
  }, []);
  const { accountStats: monthStats } = useAccountStats(range);

  const routeInfo = usePageInfo();
  const { account, isLoading: accountLoading, isError } = useAccount();

  const {
    stripeConnectLink,
    isLoading: isLinkLoading,
    error,
  } = useStripeConnectLink(
    !accountLoading && !!account && !account.stripeConnected
  );

  useEffect(() => {
    if (
      !accountLoading &&
      account &&
      !account.stripeConnected &&
      !isLinkLoading &&
      stripeConnectLink
    ) {
      addNotification({
        id: "stripe_not_connected",
        mode: "critical",
        dismissable: false,
        payload: (
          <div className="flex items-center space-x-4 text-black">
            <div className="text-white">Connect Stripe</div>
            <a
              href={stripeConnectLink.url}
              className="bg-white rounded-sm text-black px-4 py-2 text-sm"
            >
              Connect
            </a>
          </div>
        ),
      });
    }
  }, [
    account,
    accountLoading,
    addNotification,
    isLinkLoading,
    stripeConnectLink,
  ]);

  const { donations, isLoading: donationsLoading } = useDonations({
    initialPage: 0,
    limit: 6,
    sortBy: "createdAt",
    sortDirection: "DESC",
  });

  const recurringRevenue = useMemo(() => {
    if (!accountStats) return 0;
    const monthly =
      accountStats.plans?.find((p) => p.interval === "monthly")?._sum
        .grossAmount ?? 0;
    const weekly =
      accountStats.plans?.find((p) => p.interval === "weekly")?._sum
        .grossAmount ?? 0;
    const revenue = (monthly + 4 * weekly) / 100;
    return revenue;
  }, [accountStats]);

  const monthRecurringRevenue = useMemo(() => {
    if (!monthStats) return 0;
    const monthly =
      monthStats.plans?.find((p) => p.interval === "monthly")?._sum
        .grossAmount ?? 0;
    const weekly =
      monthStats.plans?.find((p) => p.interval === "weekly")?._sum
        .grossAmount ?? 0;
    const revenue = (monthly + 4 * weekly) / 100;
    return revenue;
  }, [monthStats]);

  return (
    <Page
      title={`${routeInfo.name} | GoGive`}
      header={routeInfo.name!}
      Icon={routeInfo.icon!}
    >
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4 grid grid-cols-4 gap-4">
          <div className="col-span-4 grid grid-cols-4 gap-4">
            <MyCard
              title="Raised"
              description="Total amount raised"
              Icon={FaHandHoldingHeart}
              value={currency((accountStats?.raised ?? 0) / 100)}
              secondaryValue={`+${currency(
                ((monthStats?.raised ?? 0) / 100).toFixed(0)
              )} this month`}
              loading={isLoading}
              highlight
            />
            <MyCard
              title="Donors"
              Icon={RiUserHeartFill}
              value={accountStats?.donors ?? 0}
              secondaryValue={`+${monthStats?.donors ?? 0} this month`}
              loading={isLoading}
            />
            <MyCard
              title="Recurring plans"
              Icon={RiUserHeartFill}
              value={currency(recurringRevenue)}
              secondaryValue={`+${currency(
                monthRecurringRevenue.toFixed(0)
              )} this month`}
              loading={isLoading}
            />
          </div>
        </div>
        <div className="grid grid-cols-4 col-span-4 gap-4">
          <Card className="col-span-2">
            <CardHeader className="flex flex-row justify-between items-center">
              <CardTitle className="text-lg font-semibold">
                Latest donations
              </CardTitle>
              <Button variant="link" asChild>
                <Link href="/donations" className="text-xs">
                  see all
                </Link>
              </Button>
            </CardHeader>
            <CardContent className="space-y-2 ">
              {donations.map((donation) => (
                <Link
                  href={`/donors/${donation.donor?.id}`}
                  key={donation.id}
                  className="flex items-center justify-start space-x-4 rounded-full bg-muted px-4 py-2 shadow cursor-pointer hover:bg-pink-50 transition-all hover:shadow-none "
                >
                  <span className="text-sm font-semibold min-w-12">
                    {currency(
                      (donation.grossAmount - donation.gogiveAmount) / 100
                    )}
                  </span>
                  <span className="text-sm text-muted-foreground text-nowrap">
                    {donation.donor
                      ? `${donation.donor.firstName} ${donation.donor.lastName}`
                      : donation.offline
                      ? "Offline"
                      : "Anonymous"}
                  </span>
                  <span className="text-xs font-light text-right flex-grow">
                    {formatDistanceToNow(donation.createdAt, {
                      addSuffix: true,
                    })}
                  </span>
                </Link>
              ))}
              {donations.length === 0 && !donationsLoading && (
                <div className="text-muted-foreground text-center font-light">
                  No donations yet
                </div>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle className="text-lg font-semibold">Summary</CardTitle>
            </CardHeader>
            {accountStats && (
              <CardContent className="space-y-2">
                <div>
                  <Link href="/donors">
                    <div className="flex items-center justify-between space-x-2 rounded-full bg-green-100 px-4 py-2 shadow cursor-pointer hover:bg-green-50 transition-all hover:shadow-none">
                      <span className="text-sm text-muted-foreground">
                        Donors
                      </span>
                      <span className="text-sm font-semibold">
                        {accountStats?.donors ?? 0}
                      </span>
                    </div>
                  </Link>
                </div>
                <div>
                  <Link href="/donations">
                    <div className="flex items-center justify-between space-x-2 rounded-full bg-pink-100 px-4 py-2 shadow cursor-pointer hover:bg-pink-50 transition-all hover:shadow-none">
                      <span className="text-sm text-muted-foreground">
                        Donations
                      </span>
                      <span className="text-sm font-semibold">
                        {accountStats?.donations ?? 0}
                      </span>
                    </div>
                  </Link>
                </div>
                <div>
                  <Link href="/recurring">
                    <div className="flex items-center justify-between space-x-2 rounded-full bg-purple-100 px-4 py-2 shadow cursor-pointer hover:bg-purple-50 transition-all hover:shadow-none">
                      <span className="text-sm text-muted-foreground">
                        Monthly plans
                      </span>
                      <span className="text-sm font-semibold">
                        {accountStats?.plans?.find(
                          (p) => p.interval === "monthly"
                        )?._count ?? 0}
                      </span>
                    </div>
                  </Link>
                </div>
                <div>
                  <Link href="/recurring">
                    <div className="flex items-center justify-between space-x-2 rounded-full bg-orange-100 px-4 py-2 shadow cursor-pointer hover:bg-orange-50 transition-all hover:shadow-none">
                      <span className="text-sm text-muted-foreground">
                        Weekly plans
                      </span>
                      <span className="text-sm font-semibold">
                        {accountStats?.plans?.find(
                          (p) => p.interval === "weekly"
                        )?._count ?? 0}
                      </span>
                    </div>
                  </Link>
                </div>
              </CardContent>
            )}
          </Card>
          <Card>
            <CardHeader>
              <CardTitle className="text-lg font-semibold">Stats</CardTitle>
            </CardHeader>
            <CardContent className="space-y-2">
              <div className="flex items-center justify-between space-x-2 rounded-full bg-blue-100 px-4 py-2">
                <span className="text-sm text-muted-foreground">Average</span>
                <span className="text-sm font-semibold">
                  {currency((accountStats?.average ?? 0) / 100)}
                </span>
              </div>
              <div className="flex items-center justify-between space-x-2 rounded-full bg-cyan-100 px-4 py-2">
                <span className="text-sm text-muted-foreground">Median</span>
                <span className="text-sm font-semibold">
                  {currency((accountStats?.median ?? 0) / 100)}
                </span>
              </div>
              <div className="flex items-center justify-between space-x-2 rounded-full bg-red-100 px-4 py-2">
                <span className="text-sm text-muted-foreground">Max</span>
                <span className="text-sm font-semibold">
                  {currency((accountStats?.max ?? 0) / 100)}
                </span>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="col-span-4">
          <Chart />
        </div>
      </div>
    </Page>
  );
}
